$navbarIncludeB2C: 1;
.mw-parser-output {
    display: inline-block;
    width: 100%;

    div.tright,
    div.floatright,
    table.floatright {
        clear: right;
        float: right
    }

    div.tleft,
    div.floatleft,
    table.floatleft {
        float: left;
        clear: left
    }

    div.tright {
        margin: 0.5em 0 1.3em 1.4em
    }

    div.tleft {
        margin: 0.5em 1.4em 1.3em 0
    }

    div.thumb {
        background-color: transparent;
        width: auto
    }

    div.thumb a {
        border-bottom: 0
    }

    div.thumbinner {
        border: 1px solid #c8ccd1;
        padding: 3px;
        background-color: #f8f9fa;
        font-size: 94%;
        text-align: center;
        overflow: hidden;
    }

    .thumbimage {
        background-color: #fff !important;
        border: 1px solid #c8ccd1 !important
    }

    .thumbcaption {
        border: 0;
        line-height: 1.4em;
        padding: 3px;
        font-size: 94%;
        text-align: left
    }
}

.fade-out {
    transform: translateX(0) !important;
    opacity: 0 !important;
    transition: opacity 0.3s, visibility 0.3s;
}

.fade-in {
    transform: translateX(0) !important;
    opacity: 1 !important;
    transition: opacity 0.3s, visibility 0.3s;
}